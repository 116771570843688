// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-scholarships-js": () => import("./../../../src/pages/our-scholarships.js" /* webpackChunkName: "component---src-pages-our-scholarships-js" */),
  "component---src-pages-trading-league-js": () => import("./../../../src/pages/trading-league.js" /* webpackChunkName: "component---src-pages-trading-league-js" */),
  "component---src-pages-whats-new-js": () => import("./../../../src/pages/what’s-new.js" /* webpackChunkName: "component---src-pages-whats-new-js" */)
}

